@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&family=Montserrat:wght@300;400;500;700&display=swap)

// Required
// Configuration
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

// Color system

$gray-600: #9ea3ab
$gray-700: #384e73
$gray-800: #3f434a
$gray-900: #191c21
$black: #030304

$grays: ("100": $gray-100,"200": $gray-200,"300": $gray-300,"400": $gray-400,"500": $gray-500,"600": $gray-600,"700": $gray-700,"800": $gray-800,"900": $gray-900)

$colors: ("blue": $blue,"indigo": $indigo,"purple": $purple,"pink": $pink,"red": $red,"orange": $orange,"yellow": $yellow,"green": $green,"teal": $teal,"cyan": $cyan,"white": $white,"gray": $gray-600,"gray-dark": $gray-800)

$primary: $black
$secondary: $gray-600
$success: $green
$info: $cyan
$warning: $yellow
$danger: $red
$light: $gray-100
$dark: $gray-900

// Spacing

$spacer: 1rem
$spacers: (0: 0,1: $spacer / 4,2: $spacer / 2,3: $spacer,4: $spacer * 1.5,5: $spacer * 3,6: $spacer * 4,7: $spacer * 5,8: $spacer * 6.5)

// Body

$body-bg: $white
$body-color: $gray-900

// Links

$link-color: $gray-900
$link-decoration: underline
$link-shade-percentage: 40%
$link-hover-color: shift-color($link-color, $link-shade-percentage)
$link-hover-decoration: none

// Components

$component-active-bg: $gray-900

// Typography

$font-family-sans-serif: 'Raleway', sans-serif

$font-family-base: $font-family-sans-serif

$headings-font-family: 'Montserrat', sans-serif
$headings-font-weight: 700

$hr-margin-y: $spacer
$hr-color: $gray-800
$hr-height: $border-width
$hr-opacity: .25

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: .375rem
$input-btn-padding-x: .75rem
$input-btn-font-family: null
$input-btn-font-size: $font-size-base
$input-btn-line-height: $line-height-base

$input-btn-focus-width: .25rem
$input-btn-focus-color-opacity: .25
$input-btn-focus-color: $gray-900
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color

$input-btn-padding-y-sm: .25rem
$input-btn-padding-x-sm: .5rem
$input-btn-font-size-sm: $font-size-sm

$input-btn-padding-y-lg: .5rem
$input-btn-padding-x-lg: 1rem
$input-btn-font-size-lg: $font-size-lg

$input-btn-border-width: $border-width

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y
$btn-padding-x:               $input-btn-padding-x
$btn-font-family:             $input-btn-font-family
$btn-font-size:               $input-btn-font-size
$btn-line-height:             $input-btn-line-height
$btn-white-space:             null // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm
$btn-padding-x-sm:            $input-btn-padding-x-sm
$btn-font-size-sm:            $input-btn-font-size-sm

$btn-padding-y-lg:            $input-btn-padding-y-lg
$btn-padding-x-lg:            $input-btn-padding-x-lg
$btn-font-size-lg:            $input-btn-font-size-lg

$btn-border-width:            $input-btn-border-width

$btn-font-weight:             $font-weight-normal
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075)
$btn-focus-width:             $input-btn-focus-width
$btn-focus-box-shadow:        $input-btn-focus-box-shadow
$btn-disabled-opacity:        .65
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125)

$btn-link-color:              $link-color
$btn-link-hover-color:        $link-hover-color
$btn-link-disabled-color:     $gray-600

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius
$btn-border-radius-sm:        $border-radius-sm
$btn-border-radius-lg:        $border-radius-lg

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out

// Forms

$input-padding-x: $input-btn-padding-x

$input-border-color: #a9a8a8

$input-focus-border-color: tint-color($component-active-bg, 50%)

$input-placeholder-color: $gray-600
$input-plaintext-color: $gray-600

$form-floating-padding-x: $input-padding-x

// Carousel

$carousel-control-color: $white
$carousel-control-width: 15%
$carousel-control-opacity: .5
$carousel-control-hover-opacity: .9
$carousel-control-transition: opacity .15s ease

$carousel-indicator-width: 30px
$carousel-indicator-height: 3px
$carousel-indicator-hit-area-height: 10px
$carousel-indicator-spacer: 3px
$carousel-indicator-opacity: .5
$carousel-indicator-active-bg: $white
$carousel-indicator-active-opacity: 1
$carousel-indicator-transition: opacity .6s ease

$carousel-caption-width: 70%
$carousel-caption-color: $white
$carousel-caption-padding-y: 1.25rem
$carousel-caption-spacer: 1.25rem

$carousel-control-icon-width: 2rem

$carousel-transition-duration: .6s
$carousel-transition: transform $carousel-transition-duration ease-in-out

@import '../../node_modules/bootstrap/scss/mixins'
@import '../../node_modules/bootstrap/scss/utilities'
@import '../../node_modules/bootstrap/scss/root'
@import '../../node_modules/bootstrap/scss/reboot'
@import '../../node_modules/bootstrap/scss/type'
@import '../../node_modules/bootstrap/scss/images'
@import '../../node_modules/bootstrap/scss/containers'
@import '../../node_modules/bootstrap/scss/grid'
@import '../../node_modules/bootstrap/scss/forms'
@import '../../node_modules/bootstrap/scss/buttons'
@import '../../node_modules/bootstrap/scss/transitions'
@import '../../node_modules/bootstrap/scss/button-group'
@import '../../node_modules/bootstrap/scss/nav'
@import '../../node_modules/bootstrap/scss/navbar'
@import '../../node_modules/bootstrap/scss/alert'
@import '../../node_modules/bootstrap/scss/close'
@import '../../node_modules/bootstrap/scss/tooltip'
@import '../../node_modules/bootstrap/scss/carousel'
@import '../../node_modules/bootstrap/scss/helpers'
@import '../../node_modules/bootstrap/scss/utilities/api'

.navbar-img
  width: 220px

#sidebar
  min-width: 250px
  max-width: 250px
  height: 100vh
  position: fixed
  top: 0
  left: 0
  z-index: 9999
  background: $white

  .nav-link
    &:hover
      background: $gray-900
      color: $gray-600

#dismiss
  width: 35px
  height: 35px
  position: absolute
  top: 10px
  right: 10px
  padding: 0

.carousel-inner
  height: 26vh

.carousel-item p
    font-size: 1.3rem

.carousel-item
  width: 100%
  height: 100%
  background-size: cover

  &:first-child
    background-image: url('../../public_html/dist/img/man-in-skyscraper.jpg')

  &:nth-child(2)
    background-image: url('../../public_html/dist/img/meeting-building.jpg')


  &:last-child
    background-image: url('../../public_html/dist/img/man-sitting-on-purple-armchair.jpg')

.h-bar
  &::before
    content: ''
    width: 3rem
    height: 0.2rem
    display: block
    position: relative
    margin: 0 auto 1.5rem

  &-light::before
    background: $white

  &-dark::before
    background: $gray-900

.section-about
  background: $gray-900
  padding-top: 6.25rem
  padding-bottom: 4.2rem
  color: $gray-600

.section-services
  padding-top: 10.84rem

  p
    color: $gray-600

  svg
    fill: $gray-600

.footer
  padding-top: 10rem
  padding-bottom: 4rem

  a:hover,
  a:focus
    svg
      fill: $gray-900

  svg
    fill: $gray-600

.overlay
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.7)
  z-index: 998
  opacity: 0
  transition: all 0.5s ease-in-out

  &.active
    display: block
    opacity: 1

@include media-breakpoint-up(sm)
  .navbar-img
    width: 300px

  .carousel-inner
    height: 35vh

  .carousel-item p
    font-size: 1.5rem

@include media-breakpoint-up(md)
  .carousel-inner
    height: 43vh

  .carousel-item p
    font-size: 1rem

  .h-bar
    &::before
      margin: 0 0 1.5rem

@include media-breakpoint-up(lg)
  .carousel-inner
    height: 52vh

@include media-breakpoint-up(xl)
  .carousel-inner
    height: 75vh

@include media-breakpoint-up(xxl)
  .carousel-inner
    height: 85vh

#additional-info-wrap
  display: none
